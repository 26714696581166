<template>
  <div class="col-12 consult-block">
    <template>
      <h3 class="consult-block__header pt-2">
        <template v-if="isZealandFlow">
          Book en Excelerate karriereekspert til et kald om dine muligheder og fordele på Excelerate.
        </template>
        <template v-else>
          Book an Excelerate Career Expert for an online consultation to hear more
          about all the benefits and opportunities using Excelerate.
        </template>
      </h3>
      <p>
        <template v-if="isZealandFlow">
          Bliv klogere på, hvordan du skiller dig ud for mængden og matcher med virksomheder om end du søger praktik,
          studiejob, fuldtidsjob, projektsamarbejde eller en helt femte karrieremulighed.
        </template>
        <template v-else>
          Get advice on how you can stand out from the crowd to match with
          exciting companies – whether it’s for an internship, student job,
          project collaboration, or another career opportunity.
        </template>
      </p>
      <div class="text-center">
        <button class="btn pink-button-consult px-4">
          <template v-if="isZealandFlow">
            Book online kald
          </template>
          <template v-else>
            Book online call
          </template>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "book-consult",
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
};
</script>

<style scoped>
.consult-block {
  width: 100%;
  /* height: 314px; */
  background: transparent linear-gradient(90deg, #5a2871 0%, #bc1e73 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000d;
  border-radius: 5px;
  opacity: 1;
  color: #fff;
}

.consult-block p {
  font-size: 14px;
}

.consult-block__header {
  /* font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    32px/48px var(--unnamed-font-family-poppins); */
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  /* font: normal normal bold 32px/48px Poppins; */
  color: white;
}

.consult-block__span {
  color: #bc1e73;
}

.pink-button-consult {
  color: #bc1e73;
  background: white;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
